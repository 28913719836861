import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import Up from '../Up/Up';
import { useLoad } from '../../../context/load';

import './style.scss';

import { ReactComponent as Loader } from '../../../assets/svgs/Loader.svg';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const loadCtx = useLoad();

  return (
    <>
      {loadCtx.load.loading.includes(false) && location.pathname === '/' && (
        <div
          className={
            loadCtx.load.loading.includes(false) ? 'loader' : 'loader off'
          }
        >
          <div className="loader__container">
            <Loader className="loader__logo" />
          </div>
        </div>
      )}
      <div className="body-container">
        <Navigation />
        <main className="main-content">{children}</main>
        <Up />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
