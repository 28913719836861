import React from 'react';
import './style.scss';

import { ReactComponent as Icon1 } from '../../../assets/svgs/Icon1.svg';
import { ReactComponent as Icon2 } from '../../../assets/svgs/Icon2.svg';
import { ReactComponent as Icon3 } from '../../../assets/svgs/Icon3.svg';
import { ReactComponent as Icon4 } from '../../../assets/svgs/Icon4.svg';
import { ReactComponent as Icon5 } from '../../../assets/svgs/Icon5.svg';
import { ReactComponent as Icon6 } from '../../../assets/svgs/Icon6.svg';
import { ReactComponent as Icon7 } from '../../../assets/svgs/Icon7.svg';
import { ReactComponent as Icon8 } from '../../../assets/svgs/Icon8.svg';

interface Props {
  icon: string;
  text: string;
}

const OfferTile = ({ icon, text }: Props) => {
  const iconArray: { [key: string]: any } = {
    Icon1,
    Icon2,
    Icon3,
    Icon4,
    Icon5,
    Icon6,
    Icon7,
    Icon8,
  };
  const CurrentIcon = iconArray[icon];

  return (
    <div className="offer-tile">
      <CurrentIcon className={`offer-tile__${icon.toLowerCase()}`} />
      <p className="offer-tile__text">{text}</p>
    </div>
  );
};

export default OfferTile;
