import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import IMAGES from './Images';
import Modal from '../../elements/Modal/Modal';
import { useLoad } from '../../../context/load';

import './style.scss';

import { ReactComponent as Loader } from '../../../assets/svgs/Loader.svg';

interface Props {
  name: string;
}

const Gallery = ({ name }: Props) => {
  const { t } = useTranslation('gallery');
  const loadCtx = useLoad();
  const [modalLoad, setModalLoad] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState({
    id: 0,
    src: '',
    alt: '',
    src480: '',
    src800: '',
    full: '',
  });

  const images = IMAGES();

  const imgLoad = (id: number) => {
    let LoadList = [...loadCtx.load.loading];
    LoadList[id - 1] = true;
    loadCtx.setLoad({ loading: LoadList });
  };

  const getPreviousImage = () => {
    const newId = current.id > 1 ? current.id - 1 : images.length - 1;
    const newEl = images.find((x) => x.id === newId) || current;
    setCurrent(newEl);
    setModalLoad(true);
  };
  const getNextImage = () => {
    const newId = current.id < images.length ? current.id + 1 : 1;
    const newEl = images.find((x) => x.id === newId) || current;
    setCurrent(newEl);
    setModalLoad(true);
  };

  return (
    <section className="gallery">
      <h2 className="gallery__title">{t('title')}</h2>
      <div className="gallery__container">
        {images.map((obj) => (
          <button
            className={`gallery__container-image${obj.id}`}
            key={obj.id}
            onClick={() => {
              setCurrent(obj);
              setModalOpen(true);
            }}
            aria-label={t('modal_open')}
          >
            <picture
              onLoad={() => {
                imgLoad(obj.id);
              }}
            >
              <source media="(max-width: 480px)" srcSet={obj.src480} />
              <source media="(max-width: 800px)" srcSet={obj.src800} />
              <img
                src={obj.src}
                alt={obj.alt}
                className={`gallery__image${obj.id}`}
              />
            </picture>
          </button>
        ))}
        <div className="gallery__square"></div>
      </div>
      {modalOpen && (
        <Modal>
          <div className="modal__toolbar">
            <button
              className="modal__button"
              onClick={() => {
                setModalOpen(false);
              }}
              aria-label={t('modal_label')}
            >
              <span className="material-icons-outlined">close</span>
            </button>
            <button
              className={'modal__button'}
              onClick={getPreviousImage}
              aria-label={t('arrow_label_l')}
            >
              <span className="material-icons-outlined">arrow_back</span>
            </button>
            <button
              className={'modal__button'}
              onClick={getNextImage}
              aria-label={t('arrow_label_r')}
            >
              <span className="material-icons-outlined">arrow_forward</span>
            </button>
          </div>
          <div className={modalLoad ? 'modal__loader' : 'modal__loader off'}>
            <Loader className="modal__loader-logo" />
          </div>
          <TransformWrapper>
            <TransformComponent>
              <img
                src={current.full}
                alt={current.alt}
                className={modalLoad ? 'modal__image off' : 'modal__image'}
                onLoad={() => {
                  setModalLoad(false);
                }}
                key={current.id}
              />
            </TransformComponent>
          </TransformWrapper>
        </Modal>
      )}
    </section>
  );
};

export default Gallery;
