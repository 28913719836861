import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

import cars1 from '../../../assets/images/about.png';

interface Props {
  name: string;
}

const About = ({ name }: Props) => {
  const { t } = useTranslation('about');

  return (
    <section className="about">
      <h2 className="about__title">{t('title')}</h2>
      <article className="about__content-container">
        <div className="about__image-border">
          <div className="about__image-container">
            <img src={cars1} alt="nasze samochody" className="about__image" />
          </div>
        </div>
        <div className="about__text-container">
          <p className="about__paragraph">
            {t('text_p1')}
            <span className="about__text-accent--light">
              {t('text_p1_accent')}
            </span>
          </p>
          <p className="about__paragraph">
            <span className="about__text-accent--light">
              {t('text_p2_accent')}
            </span>{' '}
            {t('text_p2')}
          </p>
          <p className="about__paragraph">
            {t('text_p3_1')}{' '}
            <span className="about__text-accent--dark">
              {t('text_p3_accent_1')}
            </span>
            {t('text_p3_2')}{' '}
            <span className="about__text-accent--light">
              {t('text_p3_accent_2')}
            </span>
          </p>
        </div>
      </article>
    </section>
  );
};

export default About;
