import React from 'react';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import './style.scss';

const Home = () => {
  const { t } = useTranslation('home');

  const handleClick = () => {
    scroller.scrollTo('about', {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  return (
    <header className="home">
      <h1 className="home__title">{t('title')}</h1>
      <p className="home__subtitle">{t('subtitle')}</p>
      <button className="home__button" onClick={handleClick}>
        {t('button')}
      </button>
    </header>
  );
};

export default Home;
