import React from 'react';
import { Link } from 'react-scroll';
import { Link as RouteLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './style.scss';

import { ReactComponent as Logo } from '../../../assets/svgs/Logo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation('footer');

  return (
    <footer className="footer">
      <div className="footer__copyright">
        {t('copyright')}{' '}
        <a
          href="https://www.linkedin.com/in/anna-piasecka-651119183/"
          className="footer__copyright-link"
        >
          Anna Piasecka
        </a>
      </div>
      <ul
        className={
          location.pathname === '/'
            ? 'footer__quick-links'
            : 'footer__quick-links--hidden'
        }
      >
        <li className="footer__link">
          <Link to="home" spy={false} smooth={true}>
            Home
          </Link>
        </li>
        <li className="footer__link">
          <Link to="about" spy={false} smooth={true}>
            {t('about')}
          </Link>
        </li>
        <li className="footer__link">
          <Link to="offer" spy={false} smooth={true}>
            {t('offer')}
          </Link>
        </li>
      </ul>
      <div className="footer__logo-container">
        <Logo className="footer__logo" />
      </div>
      <RouteLink
        className={
          location.pathname === '/'
            ? 'footer__link info'
            : 'footer__link info hidden'
        }
        to="/polityka-prywatnosci"
      >
        {t('privacy_policy')}
      </RouteLink>
      <div className="footer__social-media-container">
        <a
          className="footer__button"
          href="tel:511457038"
          aria-label={t('phone_label')}
        >
          <span className="material-icons-outlined">phone</span>
        </a>
        <a
          className="footer__button"
          href="mailto:kontakt@przewozyzklasa.pl"
          aria-label={t('mail_label')}
        >
          <span className="material-icons-outlined">email</span>
        </a>
        <a
          className="footer__button"
          href="https://www.facebook.com/Przewozy-z-klas%C4%85-107073871606453"
          aria-label={t('fb_label')}
        >
          <span className="material-icons-outlined">facebook</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
