import React from 'react';
import { HashRouter, Route } from 'react-router-dom';

import './themes/global.scss';

import Layout from './components/elements/Layout/Layout';
import Home from './components/pages/Home/Home';
import About from './components/pages/About/About';
import Offer from './components/pages/Offer/Offer';
import Weddings from './components/pages/Weddings/Weddings';
import Gallery from './components/pages/Gallery/Gallery';
import References from './components/pages/References/References';
import Contact from './components/pages/Contact/Contact';
import Policy from './components/pages/Policy/Policy';
import { LoadProvider } from './context/load';

function App() {
  return (
    <LoadProvider>
      <HashRouter>
        <Layout>
          <Route exact path="/">
            <Home />
            <About name="about" />
            <Offer name="offer" />
            <Weddings name="weddings" />
            <Gallery name="gallery" />
            <References name="references" />
            <Contact name="contact" />
          </Route>
          <Route path="/polityka-prywatnosci">
            <Policy />
          </Route>
        </Layout>
      </HashRouter>
    </LoadProvider>
  );
}

export default App;
