import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import './style.scss';

import { ReactComponent as Logo } from '../../../assets/svgs/Logo.svg';
import { ReactComponent as Eng } from '../../../assets/svgs/eng.svg';
import { ReactComponent as Pl } from '../../../assets/svgs/pl.svg';
import { ReactComponent as Arrow } from '../../../assets/svgs/Arrow.svg';

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('nav');
  const history = useHistory();
  const currentLanguageCode = cookies.get('i18next') || 'pl';

  const toggleNav = () => {
    setOpen(!open);

    const navbar = document.getElementsByClassName('navbar')[0];
    const buttons = document.getElementsByClassName(
      'navbar__button-container'
    )[0];

    navbar.classList.toggle('extended');
    buttons.classList.toggle('extended');
  };

  const addToUrl = (location: string) => {
    history.push(`/#${location}`);

    toggleNav();
  };

  const scrollUp = () => {
    scroll.scrollToTop();
  };

  const changeLanguageToEng = () => {
    i18next.changeLanguage('en');
  };

  const changeLanguageToPl = () => {
    i18next.changeLanguage('pl');
  };

  const location = useLocation();

  return (
    <nav className="navbar">
      <button
        className="navbar__logo-container"
        onClick={scrollUp}
        aria-label="logo"
      >
        <Logo className="navbar__logo" />
      </button>
      <button
        className={
          location.pathname === '/'
            ? 'navbar__burger-container'
            : 'navbar__burger-container--hidden'
        }
        aria-label="Open menu"
        onClick={() => {
          addToUrl('');
        }}
      >
        <div className="navbar__burger" />
      </button>

      <ul
        className={
          location.pathname === '/'
            ? 'navbar__button-container'
            : 'navbar__button-container--hidden'
        }
      >
        <li className="navbar__button-back">
          <RouteLink to="/" className="navbar__back">
            <Arrow className="navbar__back-arrow" />
            <span className="navbar__back-text">{t('return')}</span>
          </RouteLink>
        </li>
        <li className="navbar__button">
          <Link
            to="about"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            onClick={() => {
              addToUrl('about');
            }}
          >
            {t('about')}
          </Link>
        </li>
        <li className="navbar__button">
          <Link
            to="offer"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            offset={-60}
            onClick={() => {
              addToUrl('offer');
            }}
          >
            {t('offer')}
          </Link>
        </li>
        <li className="navbar__button">
          <Link
            to="weddings"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            onClick={() => {
              addToUrl('weddings');
            }}
          >
            {t('weddings')}
          </Link>
        </li>
        <li className="navbar__button">
          <Link
            to="gallery"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            onClick={() => {
              addToUrl('gallery');
            }}
          >
            {t('gallery')}
          </Link>
        </li>
        <li className="navbar__button">
          <Link
            to="references"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            offset={-150}
            onClick={() => {
              addToUrl('feedback');
            }}
          >
            {t('references')}
          </Link>
        </li>
        <li className="navbar__button">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            activeClass="navbar__link--active"
            className="navbar__link"
            offset={-50}
            onClick={() => {
              addToUrl('contact');
            }}
          >
            {t('contact')}
          </Link>
        </li>
      </ul>
      <div className="navbar__language-container">
        <button
          className={
            currentLanguageCode === 'en' ? 'navbar__eng' : 'navbar__eng--off'
          }
          disabled={currentLanguageCode === 'en'}
          onClick={changeLanguageToEng}
          aria-label="switch language to english"
        >
          <Eng />
        </button>
        <div className="navbar__line" />
        <button
          className={
            currentLanguageCode === 'pl' ? 'navbar__pl' : 'navbar__pl--off'
          }
          disabled={currentLanguageCode === 'pl'}
          onClick={changeLanguageToPl}
          aria-label="przełącz język na polski"
        >
          <Pl />
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
