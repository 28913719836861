import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

import wedding from '../../../assets/images/wedding.png';

interface Props {
  name: string;
}

const Weddings = ({ name }: Props) => {
  const { t } = useTranslation('weddings');
  return (
    <>
      <section className="weddings">
        <h2 className="weddings__title">{t('title')}</h2>
        <article className="weddings__content-container">
          <div className="weddings__text-container">
            <p className="weddings__paragraph">{t('p1')}</p>
            <p className="weddings__paragraph">{t('p2')}</p>
            <p className="weddings__paragraph">{t('p3')}</p>
            <p className="weddings__paragraph--blue--bold">{t('p4')}</p>
            <p className="weddings__paragraph--blue--bold">{t('p5')}</p>
          </div>
          <div className="weddings__image-border">
            <div className="weddings__image-container">
              <img
                src={wedding}
                alt="para młoda z bukietem"
                className="weddings__image"
              />
            </div>
          </div>
        </article>
      </section>
      <div className="page-line"></div>
    </>
  );
};

export default Weddings;
