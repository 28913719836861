import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './style.scss';

interface Props {
  name: string;
}

const Contact = ({ name }: Props) => {
  const { t } = useTranslation('contact');

  const [nameSurname, setNameSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');
  const [info, setInfo] = useState(['', '']);

  const changeNameSurname = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[A-Za-z\s]*$/)) setNameSurname(e.target.value);
  };

  const changePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^\+?[0-9\s]*$/)) setPhone(e.target.value);
  };

  const changeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[a-z0-9.!#$%@&'*+/=?^_`{|}~-]*$/))
      setMail(e.target.value);
  };

  const changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const resetForm = () => {
    setNameSurname('');
    setPhone('');
    setMail('');
    setMessage('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (nameSurname && mail && message) {
      axios({
        method: 'post',
        url: './api/app.php',
        headers: { 'content-type': 'application/json' },
        data: {
          name: nameSurname,
          phone: phone,
          mail: mail,
          msg: message,
        },
      })
        .then((res) => {
          setInfo(['Wiadomość została wysłana! :)', '']);
          resetForm();
        })
        .catch((err) => {
          setInfo(['Nie udało się wysłać :(', 'contact__output--error']);
        });
    } else {
      let innerHTML = 'Wypełnij następujące pola: ';
      if (!nameSurname) innerHTML += 'imię i nazwisko';
      if (!nameSurname && (!mail || !message)) innerHTML += ', ';
      if (!mail) innerHTML += 'adres e-mail';
      if (!mail && !message) innerHTML += ', ';
      if (!message) innerHTML += 'wiadomość';

      setInfo([innerHTML, 'contact__output--error']);
    }
  };

  return (
    <section className="contact">
      <h2 className="contact__title">{t('title')}</h2>
      <p className="contact__subtitle">{t('subtitle')}</p>
      <article className="contact__content-container">
        <div className="contact__data">
          <div className="contact__data-section">
            <h3 className="contact__data-subtitle">{t('phone')}</h3>
            <p className="contact__data-line">{t('p1')}</p>
            <p className="contact__data-line">{t('p2')}</p>
          </div>
          <div className="contact__data-section">
            <h3 className="contact__data-subtitle">{t('contact_data')}</h3>
            <p className="contact__data-line">kontakt@przewozyzklasa.pl</p>
            <p className="contact__data-line">NIP: 5213783649</p>
            <p className="contact__data-line">Marzanny 7/54</p>
            <p className="contact__data-line">02-649 Warszawa</p>
          </div>
        </div>
        <form className="contact__form" onSubmit={handleSubmit} method="POST">
          <p className="contact__form-optional">{t('opt')}</p>
          <input
            type="text"
            placeholder={t('placeholder_name')}
            className="contact__form-input--long"
            value={nameSurname}
            onChange={changeNameSurname}
          />
          <div>
            <input
              type="text"
              placeholder={t('placeholder_phone')}
              className="contact__form-input--short"
              value={phone}
              onChange={changePhone}
            />
            <input
              type="email"
              placeholder={t('placeholder_mail')}
              className="contact__form-input--short"
              value={mail}
              onChange={changeMail}
            />
          </div>
          <textarea
            placeholder={t('placeholder_message')}
            className="contact__form-input--big"
            value={message}
            onChange={changeMessage}
          />
          <div className={`contact__output ${info[1]}`}>{info[0]}</div>
          <button type="submit" className="contact__form-button">
            {t('button')}
          </button>
        </form>
      </article>
    </section>
  );
};

export default Contact;
