import React, { createContext, useState, useContext } from 'react';

const LoadContext = createContext({ loading: new Array(11).fill(false) });
const SetLoadContext = createContext<null | React.Dispatch<
  React.SetStateAction<{ loading: boolean[] }>
>>(null);

export const LoadProvider = ({ children }: { children: any }) => {
  const [load, setLoad] = useState({ loading: new Array(11).fill(false) });

  return (
    <SetLoadContext.Provider value={setLoad}>
      <LoadContext.Provider value={load}>{children}</LoadContext.Provider>
    </SetLoadContext.Provider>
  );
};

export const useLoad = () => {
  const load = useContext(LoadContext);
  const setLoad = useContext(SetLoadContext);
  if (setLoad === null) throw new Error();
  return { load, setLoad };
};
