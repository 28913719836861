import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

import OfferTile from '../../tiles/OfferTile/OfferTile';

interface Props {
  name: string;
}

const Offer = ({ name }: Props) => {
  const { t } = useTranslation('offer');

  const offers = [
    { icon: 'Icon1', text: t('tile_1') },
    { icon: 'Icon2', text: t('tile_2') },
    { icon: 'Icon3', text: t('tile_3') },
    {
      icon: 'Icon4',
      text: t('tile_4'),
    },
    { icon: 'Icon5', text: t('tile_5') },
    { icon: 'Icon6', text: t('tile_6') },
    {
      icon: 'Icon7',
      text: t('tile_7'),
    },
    { icon: 'Icon8', text: t('tile_8') },
  ];

  return (
    <section className="offer">
      <h2 className="offer__title">{t('title')}</h2>
      <article className="offer__content-container">
        <p className="offer__text-top">{t('text_top')}</p>
        <div className="offer__icons-container">
          {offers.map((val, k) => (
            <OfferTile text={val.text} icon={val.icon} key={k} />
          ))}
        </div>
        <p className="offer__text-bottom">{t('text_bottom_1')}</p>
        <p className="offer__text-bottom">
          {t('text_bottom_2')}{' '}
          <span className="offer__text-bold">{t('text_bottom_3')}</span>
        </p>
      </article>
    </section>
  );
};

export default Offer;
