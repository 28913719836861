import { useTranslation } from 'react-i18next';

import i1full from '../../../assets/images/g1full.jpg';
import i2full from '../../../assets/images/g2full.jpg';
import i3full from '../../../assets/images/g3full.jpg';
import i4full from '../../../assets/images/g4full.jpg';
import i5full from '../../../assets/images/g5full.jpg';
import i6full from '../../../assets/images/g6full.jpg';
import i7full from '../../../assets/images/g7full.jpg';
import i8full from '../../../assets/images/g8full.jpg';
import i9full from '../../../assets/images/g9full.jpg';
import i10full from '../../../assets/images/g10full.jpg';
import i11full from '../../../assets/images/g11full.jpg';

import image1 from '../../../assets/images/galeria1.png';
import image2 from '../../../assets/images/galeria2.png';
import image3 from '../../../assets/images/galeria3.png';
import image4 from '../../../assets/images/galeria4.png';
import image5 from '../../../assets/images/galeria5.png';
import image6 from '../../../assets/images/galeria6.png';
import image7 from '../../../assets/images/galeria7.png';
import image8 from '../../../assets/images/galeria8.png';
import image9 from '../../../assets/images/galeria9.png';
import image10 from '../../../assets/images/galeria10.png';
import image11 from '../../../assets/images/galeria11.png';

import i1800 from '../../../assets/images/g1-800.png';
import i2800 from '../../../assets/images/g2-800.png';
import i3800 from '../../../assets/images/g3-800.png';
import i4800 from '../../../assets/images/g4-800.png';
import i5800 from '../../../assets/images/g5-800.png';
import i6800 from '../../../assets/images/g6-800.png';
import i7800 from '../../../assets/images/g7-800.png';
import i8800 from '../../../assets/images/g8-800.png';
import i9800 from '../../../assets/images/g9-800.png';
import i10800 from '../../../assets/images/g10-800.png';
import i11800 from '../../../assets/images/g11-800.png';

import i1480 from '../../../assets/images/g1-480.png';
import i2480 from '../../../assets/images/g2-480.png';
import i3480 from '../../../assets/images/g3-480.png';
import i4480 from '../../../assets/images/g4-480.png';
import i5480 from '../../../assets/images/g5-480.png';
import i6480 from '../../../assets/images/g6-480.png';
import i7480 from '../../../assets/images/g7-480.png';
import i8480 from '../../../assets/images/g8-480.png';
import i9480 from '../../../assets/images/g9-480.png';
import i10480 from '../../../assets/images/g10-480.png';
import i11480 from '../../../assets/images/g11-480.png';

const IMAGES = () => {
  const { t } = useTranslation('gallery');
  const images = [
    {
      id: 1,
      src: image1,
      src800: i1800,
      src480: i1480,
      full: i1full,
      alt: t('alt1'),
    },
    {
      id: 2,
      src: image2,
      src800: i2800,
      src480: i2480,
      full: i2full,
      alt: t('alt2'),
    },
    {
      id: 3,
      src: image3,
      src800: i3800,
      src480: i3480,
      full: i3full,
      alt: t('alt3'),
    },
    {
      id: 4,
      src: image4,
      src800: i4800,
      src480: i4480,
      full: i4full,
      alt: t('alt4'),
    },
    {
      id: 5,
      src: image5,
      src800: i5800,
      src480: i5480,
      full: i5full,
      alt: t('alt5'),
    },
    {
      id: 6,
      src: image6,
      src800: i6800,
      src480: i6480,
      full: i6full,
      alt: t('alt6'),
    },
    {
      id: 7,
      src: image7,
      src800: i7800,
      src480: i7480,
      full: i7full,
      alt: t('alt7'),
    },
    {
      id: 8,
      src: image8,
      src800: i8800,
      src480: i8480,
      full: i8full,
      alt: t('alt8'),
    },
    {
      id: 9,
      src: image9,
      src800: i9800,
      src480: i9480,
      full: i9full,
      alt: t('alt9'),
    },
    {
      id: 10,
      src: image10,
      src800: i10800,
      src480: i10480,
      full: i10full,
      alt: t('alt10'),
    },
    {
      id: 11,
      src: image11,
      src800: i11800,
      src480: i11480,
      full: i11full,
      alt: t('alt11'),
    },
  ];

  return images;
};

export default IMAGES;
