import React from 'react';

import './style.scss';
import { useTranslation } from 'react-i18next';

const Policy = () => {
  const { t } = useTranslation('privacy');

  return (
    <section className="policy">
      <h2 className="policy__title">{t('title')}</h2>
      <ol className="policy__container">
        <li className="policy__subtitle">{t('point1')}</li>
        <ul className="policy__text">
          <li>{t('p1_1')}</li>
          <li>{t('p1_2')}</li>
          <li>{t('p1_3')}</li>
          <li>{t('p1_4')}</li>
          <li>{t('p1_5')}</li>
          <li>{t('p1_6')}</li>
          <li>{t('p1_7')}</li>
          <li>
            {t('p1_8')}
            <ul>
              <li>{t('p1_8_1')}</li>
              <li>{t('p1_8_2')}</li>
            </ul>
          </li>
          <li>
            {t('p1_9')}
            <ol>
              <li>{t('p1_9_1')}</li>
              <li>{t('p1_9_2')}</li>
            </ol>
          </li>
        </ul>
        <li className="policy__subtitle">{t('point2')}</li>
        <ul className="policy__text">
          <li>{t('p2_1')}</li>
          <li>{t('p2_2')}</li>
          <li>{t('p2_3')}</li>
          <li>{t('p2_4')}</li>
          <li>{t('p2_5')}</li>
        </ul>
        <li className="policy__subtitle">HOSTING</li>
        <ul className="policy__text">
          <li>{t('p3')}</li>
        </ul>
        <li className="policy__subtitle">{t('point4')}</li>
        <ul className="policy__text">
          <li>{t('p4_1')}</li>
          <li>
            {t('p4_2')}
            <ul>
              <li>{t('p4_2_1')}</li>
              <li>{t('p4_2_2')}</li>
              <li>{t('p4_2_3')}</li>
              <li>{t('p4_2_4')}</li>
              <li>{t('p4_2_5')}</li>
            </ul>
          </li>
          <li>{t('p4_3')}</li>
          <li>{t('p4_4')}</li>
          <li>{t('p4_5')}</li>
          <li>{t('p4_6')}</li>
          <li>{t('p4_7')}</li>
        </ul>
        <li className="policy__subtitle">{t('point5')}</li>
        <ul className="policy__text">
          <li>{t('p5_1')}</li>
          <li>{t('p5_2')}</li>
          <li>{t('p5_3')}</li>
          <li>{t('p5_4')}</li>
        </ul>
      </ol>
    </section>
  );
};

export default Policy;
