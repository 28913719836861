import React, { useEffect, useRef, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

import './style.scss';

const modalRoot = document.getElementById('modal');

const Modal: FunctionComponent = ({ children }) => {
  const elRef = useRef(document.createElement('div'));

  useEffect(() => {
    if (!modalRoot) {
      return;
    }
    modalRoot.appendChild(elRef.current);
    let ref = elRef.current;
    return () => {
      modalRoot.removeChild(ref);
    };
  }, []);

  return createPortal(<div className="modal">{children}</div>, elRef.current);
};

export default Modal;
