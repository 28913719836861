import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReferenceTile from '../../tiles/ReferenceTile/ReferenceTile';
import { ReactComponent as Arrow } from '../../../assets/svgs/Arrow.svg';

import './style.scss';

interface Props {
  name: string;
}

interface Reference {
  bolded: string;
  text: string;
  reviewer: string;
}

const References = ({ name }: Props) => {
  const { t } = useTranslation('references');

  const referenceList: Reference[] = [
    {
      bolded: t('ref1_bold'),
      text: t('ref1_text'),
      reviewer: t('ref1_reviewer'),
    },
    {
      bolded: t('ref2_bold'),
      text: t('ref2_text'),
      reviewer: t('ref2_reviewer'),
    },
    {
      bolded: t('ref3_bold'),
      text: t('ref3_text'),
      reviewer: t('ref3_reviewer'),
    },
    {
      bolded: t('ref4_bold'),
      text: t('ref4_text'),
      reviewer: t('ref4_reviewer'),
    },
    {
      bolded: t('ref5_bold'),
      text: t('ref5_text'),
      reviewer: t('ref5_reviewer'),
    },
    {
      bolded: t('ref6_bold'),
      text: t('ref6_text'),
      reviewer: t('ref6_reviewer'),
    },
    {
      bolded: t('ref7_bold'),
      text: t('ref7_text'),
      reviewer: t('ref7_reviewer'),
    },
  ];

  const [currentReview, setCurrentReview] = useState(0);

  const getPreviousReview = () => {
    const newCurrent =
      currentReview === referenceList.length - 1 ? 0 : currentReview + 1;
    setCurrentReview(newCurrent);
  };

  const getNextReview = () => {
    const newCurrent =
      currentReview === 0 ? referenceList.length - 1 : currentReview - 1;
    setCurrentReview(newCurrent);
  };

  return (
    <section
      className={referenceList.length > 0 ? 'references' : 'references--empty'}
    >
      <h2 className="references__title">{t('title')}</h2>

      <article className="references__content-container">
        <button
          className={
            referenceList.length === 1
              ? 'references__button-l--single'
              : 'references__button-l'
          }
          onClick={getPreviousReview}
          aria-label={t('arrow_label_l')}
        >
          <Arrow className="references__arrow-l" />
        </button>
        <button
          className={
            referenceList.length === 1
              ? 'references__button-r--single'
              : 'references__button-r'
          }
          onClick={getNextReview}
          aria-label={t('arrow_label_r')}
        >
          <Arrow className="references__arrow-r" />
        </button>
        {referenceList.length > 0 && (
          <ReferenceTile
            bolded={referenceList[currentReview].bolded}
            text={referenceList[currentReview].text}
            reviewer={referenceList[currentReview].reviewer}
          />
        )}
      </article>
    </section>
  );
};

export default References;
