import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import './style.scss';

import { ReactComponent as Arrow } from '../../../assets/svgs/Arrow.svg';

const Up = () => {
  const scrollUp = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const up = document.getElementsByClassName('up')[0];
    const changeVisibility = () => {
      if (window.scrollY >= window.screen.height / 3) {
        up.classList.add('visible');
      } else {
        up.classList.remove('visible');
      }

      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        up.classList.add('scrolled');
      } else {
        up.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', changeVisibility);

    return () => {
      window.removeEventListener('scroll', changeVisibility);
    };
  }, []);

  return (
    <button onClick={scrollUp} className="up" aria-label="Do góry">
      <Arrow className="up__arrow" />
    </button>
  );
};

export default Up;
