import React from 'react';

import './style.scss';

interface Props {
  bolded: string;
  text: string;
  reviewer: string;
}

const ReferenceTile = ({ bolded, text, reviewer }: Props) => {
  return (
    <div className="reference-tile">
      <h3 className="reference-tile__title">{bolded}</h3>
      <p className="reference-tile__text">{text}</p>
      <p className="reference-tile__reviewer">{reviewer}</p>
    </div>
  );
};

export default ReferenceTile;
